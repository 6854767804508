import React from 'react';
import { useMediaQuery } from 'react-responsive';
import './EditorHeading.css';

const EditorHeading = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

    return (
        <div className={`editor-heading-container ${isMobile ? 'mobile' : ''}`}>
            <h2 className="editor-heading">Craft the Perfect Marketing Paper Bag</h2>
            <p className="editor-description">
                Design paper bags that don’t just carry products but also carry your brand’s message.
            </p>
        </div>
    );
};

export default EditorHeading;
