import React from "react";
import "./AboutUs.css"; // Import the updated CSS file
import GreenEarth from "../../images/greenearth.svg";
import GreenMission from "../../images/greenmission.svg";
import AboutUsImage from "../../images/blacklogo.svg"; 
import { useNavigate } from "react-router-dom";

const AboutUs = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="about-us-container">
        <header className="about-us-header">
          <h1>We are the <span className="text-logo-black-background">BIGDOON</span></h1>
          <div class="quote-container">
            <blockquote class="quote">
              Empowering Your Business Growth with <span className="quote-highlight">Innovative Outdoor Marketing,</span> While Championing a Plastic-Free India!
            </blockquote>
          </div>

        </header>
        <div className="about-us-container-body">
          <div className="about-us-column left-column">
          <img src={GreenMission} alt="Description of SVG" className="svg-image" />
          <h2 className="our-vision-heading">Our Mission</h2>
            <p className="our-vision-desc">We strive to elevate outdoor marketing to new heights by innovating strategies like paper bag and glass marketing. Our mission is to phase out plastic and introduce sustainable, creative mediums, ensuring our clients reach wider, more engaged audiences.</p>
          </div>
          <div className="about-us-column middle-column">
          <img src={AboutUsImage} alt="Description of SVG" className="svg-image" />
          <h2 className="about-us-heading">About Us</h2>
            <p className="our-vision-desc">At BIGDOON, we are revolutionizing outdoor marketing in India by pioneering sustainable advertising solutions. We are dedicated to eliminating plastic waste by replacing traditional plastic bags and glasses with innovative, eco-friendly paper alternatives. Our focus is on transforming how brands connect with their audiences, offering creative and impactful marketing strategies that resonate with the modern, environmentally-conscious consumer. We are committed to leading the way in outdoor marketing, helping our clients achieve meaningful engagement while supporting a plastic-free future. Join us in making a significant impact on both the industry and the environment.</p>
          </div>
          <div className="about-us-column right-column">
            <img src={GreenEarth} alt="Description of SVG" className="svg-image" />
            <h2 className="our-vision-heading">Our Vision</h2>
            <p className="our-vision-desc">To create a vibrant, plastic-free India by merging outdoor marketing excellence with environmental action. We remove polythene from our ecosystem while planting trees to foster a cleaner, healthier earth.</p>
          </div>
        </div>
        <div className="about-us-contact-div">
          <h2>HAVE A QUESTION</h2>
        </div>
        <div className="about-us-contact-div-content">
          <p>Let's TALK</p>
          <button type="submit" className="about-us-contact-button" onClick={()=> navigate("/contact")}>Contact Us</button>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
