import React, { useState } from 'react';
import './Careers.css'; // Assuming the CSS is in a file named Careers.css
import { Button, Popover } from 'antd';

const Careers = () => {

  const jobListings = [
    {
      title: "Frontend Developer",
      description: "We are looking for a skilled frontend developer to join our team.",
      full_desc: "As a Frontend Developer, you will work closely with our product and design teams to create visually appealing and highly functional web applications. You will be responsible for building and optimizing responsive web interfaces, maintaining code quality, and collaborating with backend developers to integrate APIs. The ideal candidate should have a strong command of JavaScript, React.js, HTML, and CSS, along with experience in version control systems like Git. If you are passionate about delivering high-quality user experiences and eager to innovate in a fast-paced environment, we’d love to hear from you."
    },
    {
      title: "Backend Developer",
      description: "We need a talented backend developer to build robust APIs.",
      full_desc: "As a Backend Developer, you will be responsible for developing and maintaining server-side logic, ensuring high performance and responsiveness to requests from the frontend. You will design and build APIs, manage databases, and handle data integration and security protocols. The ideal candidate should have experience with Node.js, Python, or Java, and knowledge of frameworks like Express or Django. Proficiency in SQL/NoSQL databases and experience in cloud platforms (AWS, Azure, etc.) is a plus. If you are passionate about solving complex backend challenges and building scalable solutions, we invite you to join our dynamic team."
    },
    {
      title: "UI/UX Designer",
      description: "Seeking a creative UI/UX designer with a strong portfolio.",
      full_desc: "As a UI/UX Designer, you will be instrumental in shaping the look and feel of our products. You will work closely with product managers and developers to create intuitive, user-centered designs that meet both business objectives and user needs. You should have a strong portfolio showcasing your design process, from research and wireframing to prototyping and final design. Proficiency in design tools like Figma, Sketch, or Adobe XD is essential. Experience with user testing and A/B testing, along with a solid understanding of accessibility standards, is highly desired. If you are passionate about design and eager to make a meaningful impact, we want to hear from you."
    },
    {
      title: "Product Manager",
      description: "Join us as a product manager to help shape our roadmap.",
      full_desc: "As a Product Manager, you will be responsible for leading cross-functional teams to define, build, and launch innovative products. You will gather and prioritize product and customer requirements, define the product vision, and work closely with engineering, sales, marketing, and support teams to ensure revenue and customer satisfaction goals are met. The ideal candidate should have experience in product management, excellent communication skills, and a strong analytical mindset. Familiarity with Agile methodologies and tools like JIRA is preferred. If you are a strategic thinker with a passion for driving product success, we encourage you to apply."
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const jobsPerPage = 10; // Number of job cards to display per page

  const [visiblePopover, setVisiblePopover] = useState(null);

  // Calculate the index of the first and last job to display on the current page
  const indexOfLastJob = currentPage * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = jobListings.slice(indexOfFirstJob, indexOfLastJob);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handlePopoverVisibleChange = (jobIndex) => {
    setVisiblePopover(visiblePopover === jobIndex ? null : jobIndex);
  };

  return (
    <div className="container">
      {/* <div className="left-side">
        <img
          src={ImageBanner}
          alt="Career banner image"
          style={{ maxWidth: '100%', height: 'auto', objectFit:'fill' }}
        />
      </div> */}

      {/* Right Side: Careers Content */}
      <div className="right-side">
        <div className="careers">
          <header className="carrer-header">
            <h1>Join Our Team at <span className="text-logo-black-background">BIGDOON</span></h1>
            <div className="carrer-desc-container">
              <blockquote className="carrer-desc">
                At BIGDOON, we’re revolutionizing outdoor marketing by turning everyday items like paper bags into powerful advertising tools. Our mission is to connect brands with consumers in creative and impactful ways through innovative print advertising solutions.
                <br />We're looking for passionate, forward-thinking individuals who want to make a difference in the advertising world. If you’re excited about creativity, innovation, and the potential of unique outdoor marketing strategies, BIGDOON is the place for you!
                <br />Join our dynamic team and be a part of a fast-growing startup that values fresh ideas, collaboration, and growth. Whether you're a creative designer, a strategic thinker, or a go-getter in sales, we have a place for you!
                <br /><br /><span className="carrer-desc-highlight">Come grow with us and help redefine advertising!</span>
              </blockquote>
            </div>
          </header>

          {/* Job Listings Section with Pagination */}
          <div className="job-listings">
            {currentJobs.map((job, index) => (
              <div key={index} className="job-card">
                <h3>{job.title}</h3>
                <p>{job.description}</p>
                <Popover
                  content={<div style={{ fontSize:'18px'}}>{job.full_desc}</div>}
                  title={<div style={{ fontSize:'22px'}}>{job.title}</div>}
                  trigger="click"
                  open={visiblePopover === index}
                  onOpenChange={() => handlePopoverVisibleChange(index)}
                  overlayStyle={{ width: '80%' }} // Set width here
                >
                  <Button id='job-card-button'>Read More</Button>
                </Popover>
              </div>
            ))}

            {/* Pagination Controls */}
            <div className='pagination-container'>
              <div className="pagination">
                <button
                  onClick={() => paginate(currentPage - 1)}
                  disabled={currentPage === 1}
                  className="pagination-button"
                >
                  Previous
                </button>
                <button
                  onClick={() => paginate(currentPage + 1)}
                  disabled={indexOfLastJob >= jobListings.length}
                  className="pagination-button"
                >
                  Next
                </button>
              </div>
            </div>
          </div>

          {/* Company Culture Section */}
          <div className="company-culture">
            <h2>Our Company Culture</h2>
            <p>
              Our culture is built on trust, collaboration, and innovation. We believe in a
              friendly and open work environment.
            </p>
            <ul>
              <li>Innovative Work</li>
              <li>Growth Opportunities</li>
              <li>Inclusive Environment</li>
            </ul>
          </div>

          {/* Application Instructions Section */}
          <div className="application-instructions">
            <h2>How to Apply</h2>
            <p>
              To apply, please send your resume and cover letter to{' '}
              <a href="mailto:contact@bigdoon.com" style={{marginTop:'15px'}}>contact@BIGDOON.com</a>.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Careers;
