import React, { useState } from 'react';
import './ContactUs.css';
import { FaEnvelope } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import { FaXTwitter } from 'react-icons/fa6';
import { BiSolidPhoneCall } from 'react-icons/bi';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: ''
  });

  const apiUrl = process.env.REACT_APP_API_URL;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${apiUrl}/api/contact`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      const data = await response.json();
      if (data.success) {
        toast.success('Message sent successfully');
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          message: ''
        });
      } else {
        toast.error('Error sending message');
      }
    } catch (error) {
      toast.error('Server error');
    }
  };

  return (
    <div className='contact-container'> 
      <header className="contact-us-header">
        <h1>Contact to <span className="text-logo-black-background">BIGDOON</span> Team</h1>
        <p>Got questions? Connect with BIGDOON! We're redefining outdoor marketing with eco-friendly paper bags. Join us in our mission for a greener India. Reach out to us 24/7!</p>
      </header>
      <div className="contact-container-body">
        <div className="contact-form">
          <form onSubmit={handleSubmit}>
            <div className="name-fields">
              <div className="field">
                <label htmlFor="first-name">First Name</label>
                <input
                  type="text"
                  id="first-name"
                  name="firstName"
                  placeholder='First name'
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="field">
                <label htmlFor="last-name">Last Name</label>
                <input
                  type="text"
                  id="last-name"
                  name="lastName"
                  placeholder='Last name'
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder='your@company.com'
              value={formData.email}
              onChange={handleChange}
              required
            />

            <label htmlFor="phone">Phone</label>
            <input
              type="text"
              id="phone"
              name="phone"
              placeholder='+91xxxxxxxxxx'
              value={formData.phone}
              onChange={handleChange}
            />

            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              rows="4"
              placeholder='Leave us a message...'
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>

            <button type="submit">Send Message</button>
          </form>
        </div>

        <div className="contact-info">
          <h2>Chat with us</h2>
          <p>Need assistance? Chat with our team now by clicking the floating button below.</p>
          <div className="contact-method">
            <a href="mailto:info@example.com" className="contact-link">
              <FaEnvelope className="icon" />
              <p>Shoot us an email</p>
            </a>
          </div>
          <div className="contact-method">
            <a href="https://x.com" className="contact-link" target="_blank" rel="noopener noreferrer">
              <FaXTwitter className="icon" />
              <p>Message us on X</p>
            </a>
          </div>
          <h2 style={{ marginTop: '3rem' }}>Call us</h2>
          <p style={{ marginBottom: '8px', marginTop: '8px' }}>Call our team Mon-Fri from 9am to 5pm.</p>
          <div className="contact-method contact-phone" style={{ marginTop: '-5px' }}>
            <BiSolidPhoneCall className="icon" />
            <p>+919634830671</p>
          </div>
        </div>
      </div>
      <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </div>
  );
};

export default ContactUs;
