import React from 'react';
import './legal.css'; // Import the CSS file
import { FaPhone, FaEnvelope } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
  return (
    <div className="legal-container"> 
      <h1>Privacy Policy</h1>
      <p><strong>Last updated:</strong> September 4, 2024</p>

      <p>At <strong>BIGDOON</strong>, accessible from <a href="http://www.bigdoon.com">www.BIGDOON.com</a>, your privacy is of utmost importance to us. This Privacy Policy document outlines the types of information we collect and how we use, protect, and disclose it. By using our website, you agree to the collection and use of information in accordance with this policy.</p>

      <h2>1. Information We Collect</h2>
      <p>We collect several types of information from and about users of our website, including:</p>
      <ul>
        <li><strong>Personal Information:</strong> Such as your name, email address, phone number, billing address, and other contact details, which you provide to us voluntarily when you subscribe to our newsletter, fill out a contact form, make a purchase, or communicate with us.</li>
        <li><strong>Payment Information:</strong> When you make a purchase or payment through our website, we may collect payment details such as credit card numbers, expiration dates, and security codes. This information is securely processed by our third-party payment gateway providers and is not stored on our servers.</li>
        <li><strong>Usage Data:</strong> Information automatically collected when you use the website, such as your IP address, browser type, operating system, referring URLs, and details of your visits to our website, including traffic data, location data, logs, and other communication data.</li>
        <li><strong>Cookies and Tracking Technologies:</strong> We use cookies and similar tracking technologies to monitor activity on our website and store certain information. You can instruct your browser to refuse all cookies or indicate when a cookie is being sent.</li>
      </ul>

      <h2>2. How We Use Your Information</h2>
      <p>We use the information we collect for various purposes, including:</p>
      <ul>
        <li>To provide, maintain, and improve our website and services.</li>
        <li>To process your payments and complete your transactions securely.</li>
        <li>To respond to your comments, questions, and requests and provide customer service.</li>
        <li>To send you marketing and promotional communications, if you have opted in to receive them.</li>
        <li>To monitor and analyze usage and trends to improve your experience on our website.</li>
        <li>To detect, prevent, and address technical issues.</li>
      </ul>

      <h2>3. Sharing Your Information</h2>
      <p>We do not sell or rent your personal information to third parties. However, we may share your information in the following situations:</p>
      <ul>
        <li><strong>Service Providers:</strong> We may share your information with trusted service providers who perform functions on our behalf, such as payment processing, hosting services, data analysis, email delivery, and customer service.</li>
        <li><strong>Payment Gateway Providers:</strong> When you make a payment on our website, your payment information is processed directly by our payment gateway providers. We do not store or have access to your full payment information.</li>
        <li><strong>Legal Requirements:</strong> We may disclose your information if required by law or in response to valid requests by public authorities (e.g., a court or government agency).</li>
        <li><strong>Business Transfers:</strong> If we are involved in a merger, acquisition, or asset sale, your personal information may be transferred.</li>
      </ul>

      <h2>4. Data Security</h2>
      <p>We use reasonable administrative, technical, and physical security measures to protect your personal information from unauthorized access, use, alteration, or disclosure. Our payment gateway providers comply with industry standards for data protection and secure processing. However, no method of transmission over the internet or method of electronic storage is 100% secure.</p>

      <h2>5. Your Rights</h2>
      <p>Depending on your location, you may have certain rights under applicable data protection laws, including:</p>
      <ul>
        <li>The right to access, update, or delete the personal information we hold about you.</li>
        <li>The right to object to the processing of your personal information.</li>
        <li>The right to withdraw your consent to the processing of your personal information.</li>
      </ul>
      <p>If you wish to exercise any of these rights, please contact us at [insert contact email].</p>

      <h2>6. Third-Party Websites</h2>
      <p>Our website may contain links to third-party websites. We do not control and are not responsible for the content or privacy practices of these websites. We encourage you to review the privacy policies of any third-party sites you visit.</p>

      <h2>7. Children's Privacy</h2>
      <p>Our website is not intended for use by children under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that a child under 13 has provided us with personal information, we will take steps to delete such information.</p>

      <h2>8. Changes to This Privacy Policy</h2>
      <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.</p>

      <h2>9. Contact Us</h2>
      <p>If you have any questions or concerns about this Privacy Policy or our practices, please contact us at:</p>
      <div className="legal-contact">
        <p>
          <strong>BIGDOON</strong><br />
          <FaEnvelope /> Email: <a href="mailto:contact@BIGDOON.com">contact@BIGDOON.com</a><br />
          <FaPhone /> Phone: <a href="tel:+919634830671">+919634830671</a>
        </p>
        <Link to="/contact" className="contact-link">Contact Us</Link>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
