import React from 'react';
import './legal.css';
import { FaPhone, FaEnvelope } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const RefundPolicy = () => {
  return (
    <div className="legal-container">
      <h1>Refund Policy</h1>

      <p>
        At <strong>BIGDOON</strong>, accessible from <a href="http://www.bigdoon.com">www.BIGDOON.com</a>, we are committed to delivering exceptional outdoor marketing services and eco-friendly advertising solutions. We strive to ensure that our clients are satisfied with our services. If you encounter any issues, please review our refund policy below.
      </p>

      <h2><strong>1. Eligibility for Refunds</strong></h2>
      <p>
        Refunds are considered under the following conditions:
      </p>
      <p>
        <strong>Service Issues:</strong> If you believe there has been a significant issue with the marketing service we provided, please contact us within 12 hours of the service purchaseed. We will review the situation and determine if a refund is appropriate.
      </p>

      <h2><strong>2. Refund Process</strong></h2>
      <p>
        To request a refund, please follow these steps:
      </p>
      <ul>
        <li>
          <strong>Contact Us:</strong> Email us at below mentioned contact platforms within 12 hours of the service purchaseed. Provide details of the issue and any relevant documentation.
        </li>
        <li>
          <strong>Review and Confirmation:</strong> Our team will review your request and respond within 5 business days. We will assess the issue and determine if a refund is warranted.
        </li>
        <li>
          <strong>Processing Time:</strong> If your request is approved, the refund will be processed within 10 business days. Please note that the actual time for the refund to appear in your account may vary based on your payment method and bank processing times.
        </li>
      </ul>

      <h2><strong>3. Non-Refundable Conditions</strong></h2>
      <p>
        Refunds will not be processed under the following circumstances:
      </p>
      <p>
        <strong>Completed Services:</strong> Once a marketing service has been fully executed and delivered as per the agreed scope, it is considered complete and non-refundable.
      </p>
      <p>
        <strong>Printing in Progress:</strong> If the printing of paper bags has already commenced, refunds cannot be processed. We initiate printing based on client approval, and once in progress, it becomes part of the service delivery.
      </p>
      <p>
        <strong>Client Change of Mind:</strong> Refunds are not provided for changes of mind or requests made after the service has been completed or the printing process has started.
      </p>

      <h2><strong>4. Service Adjustments</strong></h2>
      <p>
        If you are dissatisfied with our services, we are open to discussing adjustments or improvements rather than a refund. Please contact us to discuss how we can better meet your needs.
      </p>

      <h2><strong>5. Contact Information</strong></h2>
      <p>If you have any questions or concerns about this Refund Policy or our practices, please contact us at:</p>
      <div className="legal-contact">
        <p>
          <strong>BIGDOON</strong><br />
          <FaEnvelope /> Email: <a href="mailto:contact@BIGDOON.com">contact@BIGDOON.com</a><br />
          <FaPhone /> Phone: <a href="tel:+919634830671">+919634830671</a>
        </p>
        <Link to="/contact" className="contact-link">Contact Us</Link>
      </div>

      <h2><strong>6. Policy Changes</strong></h2>
      <p>
        BIGDOON reserves the right to update or modify this refund policy at any time. Any changes will be reflected on our website, and your continued use of our services constitutes acceptance of the updated policy.
      </p>

      <p>
        Thank you for choosing <strong>BIGDOON</strong>. We value your business and are committed to providing exceptional marketing services.
      </p>
    </div>
  );
};

export default RefundPolicy;
