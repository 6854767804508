import React from 'react';
import TypingEffect from './TypingEffect'; // Import the TypingEffect component
import '../css/TopHeading.css';

const TopHeading = () => {
    const textList = [
        { text: "OOutdoor marketing", color: "#0C831F" },
        { text: "IInnovative campaigns", color: "#0C831F" },
        { text: "BBrand visibility", color: "#0C831F" },
        { text: "EEngaging strategies", color: "#0C831F" }
    ];

    return (
        <div className='top-heading-container'>
            <h1>Let us show you the power of <TypingEffect textList={textList} speed={100} /></h1>
            <p>Maximize your brand's exposure and attract your target audience with our cutting-edge outdoor marketing strategies, crafted to captivate and engage.</p>
            {/* <div className='btn-container'>
                <div className="btn btn-one">
                    <span>REQUEST A QUOTE</span>
                </div>
            </div> */}
        </div>
    );
};

export default TopHeading;
